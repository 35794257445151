import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const query = graphql`
  query {
    pdf: directusMediaCollection(name: {eq: "downloads"}) {
      media {
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const Link = makeShortcode("Link");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <HeaderImage mdxType="HeaderImage">
      <HeaderMedia id='flug' mdxType="HeaderMedia" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Flugmedizin`}</h1>
      <p>{`Ein Flugschüler bzw. Pilot darf fliegerisch nur tätig werden, wenn er über ein gültiges Tauglichkeitszeugnis verfügt. In unserer Untersuchungsstelle werden Untersuchungen für Tauglichkeitsklasse II (Privatpiloten) und LAPL (Light Aircraft Pilot Licens ) durchgeführt.`}</p>
      <p>{`Bitte vereinbaren Sie telefonisch, persönlich oder per Email einen Untersuchungstermin. Geben Sie dabei die gewünschte Tauglichkeitsklasse und Ihr Alter an und teilen Sie uns mit, ob es sich um eine Erst-; Verlängerungs- oder Erneuerungsuntersuchung handelt.`}</p>
      <h3 {...{
        "id": "benotigte-unterlagen"
      }}>{`Benötigte Unterlagen:`}</h3>
      <ul>
        <li parentName="ul">{`Personalausweis (falls wir Sie noch nicht kennen)`}</li>
        <li parentName="ul">{`Letztes Tauglichkeitszeugnis`}</li>
        <li parentName="ul">{`Pilotenlizenzen, falls vorhanden`}</li>
        <li parentName="ul">{`Die persönliche Referenznummer (Diese wird bzw. wurde nach neuer Regelung jedem Tauglichkeitsbewerber vom LBA zugeordnet und ist dort nach Angabe Ihres Vor- und Nachnamens, dem Geburtsdatum und – Ort sowie Ihrer Lizenznummer auf dem Postweg oder per Fax zu erfragen)`}</li>
        <li parentName="ul">{`Antrag auf die Ausstellung eines Tauglichkeitszeugnis“ (Download Formular siehe unten) – bitte vorab ausfüllen`}</li>
        <li parentName="ul">{`evtl. zusätzliche Unterlagen wie Laborbefunde, Brillenpass, wichtige Vorbefunde`}</li>
        <li parentName="ul">{`Augenarztzeugnis: Privatpiloten müssen nur bei der Erstuntersuchung zum Augenarzt und bei Änderung des in Ihrem Medical eingetragenen Augenstatus (Download Formular siehe unten)`}</li>
      </ul>
      <h3 {...{
        "id": "kosten"
      }}>{`Kosten:`}</h3>
      <p>{`Die Kosten für die fliegerärztliche Untersuchung werden von den Krankenkassen nicht übernommen. Alle Untersuchungen werden nach der GOÄ (Gebührenordnung Ärzte) zu fairen Konditionen abgerechnet. Den Preis für die bei Ihnen notwendige Untersuchung können Sie gerne erfragen. Es ist zulässig Ihre Untersuchung 45 Tage vor Ablauf Ihres Medicals durchzuführen, dadurch geht in der Gültigkeitsdauer des neuen Medicals kein Verlust („45 Tage Regel“).`}</p>
      <h3 {...{
        "id": "download"
      }}>{`Download`}</h3>
      <Link external to={props.data.pdf.media[0].file.localFile.publicURL} mdxType="Link">Antrag für die Ausstellung eines Tauglichkeitszeugnisses (PDF)</Link>
      <br />
      <Link external to={props.data.pdf.media[1].file.localFile.publicURL} mdxType="Link">Augenärztlicher Untersuchungsbericht (PDF)</Link>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      